import React from "react";
import team1 from "../../assets/image/team/team-1.png";
import team2 from "../../assets/image/team/team-2.png";
import team3 from "../../assets/image/team/team-3.png";
import team4 from "../../assets/image/team/team-4.png";

function AboutTeamSection({ currLangJSON }) {
  return (
    <div className="about-team-section">
    <div className="text-bluishText font-medium text-sm mt-[72px] mb-3 text-center md:text-center">
        {currLangJSON["aboutUsPage.firstSec.storySection.greeting"]}
      </div>
      <div className=" text-4xl mb-3 text-center md:text-center font-semibold">
        <span className="text-[#F79009]">
          {
            currLangJSON[
              "aboutUsPage.firstSec.storySection.subheading.yellowText"
            ]
          }
        </span>
        , {currLangJSON["aboutUsPage.firstSec.storySection.subheading.text"]}
      </div>
      <section className="mt-16 flex justify-center items-center text-center text-base text-aboutBodyText lg:w-[60%] m-auto">
        <div className="quoted-text">
          <p className="">
            {/* <span>
            <img src={openQuotes} alt="quotes" width={200} className="mb-1" />
          </span> */}
            {currLangJSON["aboutUsPage.firstSec.storySection.description1"]}
          </p>
          <p className="mt-4">
            {currLangJSON["aboutUsPage.firstSec.storySection.description2"]}
          </p>
          <p className="mt-4">
            {currLangJSON["aboutUsPage.firstSec.storySection.description3"]}
            {/* <span>
            <img src={closeQuotes} alt="quotes" width={200} />
          </span> */}
          </p>
        </div>
      </section>
      <div className="text-bluishText font-medium text-sm mt-10 lg:mt-24 mb-3 text-center">
        {currLangJSON["aboutUsPage.teamSection.heading1"]}
      </div>
      <h1 className="text-center text-3xl font-bold">
        {currLangJSON["aboutUsPage.teamSection.heading2"]}
      </h1>
      <p className="text-center mt-4 text-aboutBodyText">
        {currLangJSON["aboutUsPage.teamSection.heading3"]}
      </p>
      <section className="flex flex-wrap justify-around mt-12 gap-14 md:gap-5 m-auto">
        <div className="basis-[260px]">
          <div>
            <img src={team1} alt="team" />
          </div>
          <div className="font-bold mt-4">Piyush R. Akhouri</div>
          <div className="text-[#185294] text-sm mt-1">Co-Founder & CEO</div>
          <div className="text-aboutBodyText text-[13px] mt-2">
            Product | Strategy | Expansion | Revenue & Profit Growth
          </div>
        </div>
        <div className="basis-[260px]">
          <div>
            <img src={team3} alt="team" />
          </div>
          <div className="font-bold mt-4">Sravan Akhil M</div>
          <div className="text-[#185294] text-sm mt-1">Co-Founder & CTO</div>
          <div className="text-aboutBodyText text-[13px] mt-2">
            Software Development | Systems Architecture | Innovation | R&D | NLP
            | Strategic Technology Planning
          </div>
        </div>
        <div className="basis-[260px]">
          <div>
            <img src={team2} alt="team" />
          </div>
          <div className="font-bold mt-4">Shobhit Prakash</div>
          <div className="text-[#185294] text-sm mt-1">Co-Founder & CMO</div>
          <div className="text-aboutBodyText text-[13px] mt-2">
            Business Growth Strategy | Business Development | Strategic
            Alliances | Go to Market
          </div>
        </div>
        <div className="basis-[260px]">
          <div>
            <img src={team4} alt="team" />
          </div>
          <div className="font-bold mt-4">Utpal Das</div>
          <div className="text-[#185294] text-sm mt-1">
            Head, Product Management
          </div>
          <div className="text-aboutBodyText text-[13px] mt-2">
            Project Management | Go-to-Market | Product Strategy & Design |
            Business Analytics
          </div>
        </div>
      </section>

      <section>
        <div className="text-bluishText font-medium text-sm mt-24 mb-3 text-center">
          {currLangJSON["aboutUsPage.valuesSection.heading1"]}
        </div>
        <h1 className="text-center text-3xl font-bold">
          {currLangJSON["aboutUsPage.valuesSection.heading2.text1"]}{" "}
          <span className="text-[#F79009]">
            {currLangJSON["aboutUsPage.valuesSection.heading2.yellowText"]}
          </span>{" "}
          {currLangJSON["aboutUsPage.valuesSection.heading2.text2"]}
        </h1>
        <p className="text-center mt-4 text-aboutBodyText">
          {currLangJSON["aboutUsPage.valuesSection.heading3"]}
        </p>
        <div className="flex flex-col w-[80%] mx-auto mt-12 gap-6">
          <div className="flex flex-col lg:flex-row gap-6">
            <div className="text-center basis-1/2 p-2">
              <div className="text-[#F79009] font-semibold text-lg">
                {currLangJSON["aboutUsPage.valuesSection.card1.heading"]}
              </div>
              <div className="text-aboutBodyText text-sm mt-2">
               {currLangJSON["aboutUsPage.valuesSection.card1.description"]}
              </div>
            </div>
            <div className="text-center basis-1/2 p-2">
              <div className="text-[#F79009] font-semibold text-lg">{currLangJSON["aboutUsPage.valuesSection.card2.heading"]}</div>
              <div className="text-aboutBodyText text-sm mt-2">
                {currLangJSON["aboutUsPage.valuesSection.card2.description"]}
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row gap-6">
            <div className="text-center basis-1/2 p-2">
              <div className="text-[#F79009] font-semibold text-lg">{currLangJSON["aboutUsPage.valuesSection.card3.heading"]}</div>
              <div className="text-aboutBodyText text-sm mt-2">
                {currLangJSON["aboutUsPage.valuesSection.card3.description"]}
              </div>
            </div>
            <div className="text-center basis-1/2 p-2">
              <div className="text-[#F79009] font-semibold text-lg">
                {currLangJSON["aboutUsPage.valuesSection.card4.heading"]}
              </div>
              <div className="text-aboutBodyText text-sm mt-2">
                {currLangJSON["aboutUsPage.valuesSection.card4.description"]}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutTeamSection;
