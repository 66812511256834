import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import LandingPage from './pages/LandingPage';
import Jobs from './pages/Jobs';
import Resources from './pages/Resources';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';
import TermsConditions from './pages/TermsAndCondition';
import About from './pages/About';
import ScrollToTop from './components/ScrollToTop';
import UnderConstruction from './pages/UnderConstruction';
import NotFoundPage from './pages/404';
import EmployerTermsAndCondition from './pages/EmployerTermsAndCondition'
import ScheduleDemo from './pages/ScheduleDemo';
import FAQ from './pages/FAQ';
import GDPRANDData from './pages/GDPRANDData'
import CookiePolicy from './pages/CookiePolicy'
import Imprint from './pages/Imprint'
import AIParser from './pages/AIParser'
import ViewJob from './pages/ViewJob';
import Access from './pages/Access';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="jobs" element={<Jobs />} />
        <Route exact path="/jobs/viewJob/:jobId" element={<ViewJob />} />
        <Route path="resources" element={<Resources />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="pricing" element={<UnderConstruction />} />
        <Route path="contact-us" element={<Contact />} />
        <Route path="privacy-policy" element={<TermsConditions />} />
        <Route path="terms-and-conditions" element={<EmployerTermsAndCondition />} />
        <Route path="about-us" element={<About />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="gdpr" element={<GDPRANDData />} />
        <Route path="imprint" element={<Imprint />} />
        <Route path="cookie-policy" element={<CookiePolicy />} />
        <Route path="schedule-demo" element={<ScheduleDemo />} />
        <Route path="under-construction" element={<UnderConstruction />} />
        <Route path="ai-parser" element={<AIParser />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/access-page" element={<Access />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
