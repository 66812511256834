import React from "react";
import "../../styles/accessPage/accessLandingPage.css";
import Icon from "../../assets/logo/hire-topbar-logo.svg";
import product1 from "../../assets/image/access/product1-icon.png";
import product2 from "../../assets/image/access/product2-icon.svg";
import product3 from "../../assets/image/access/product3-icon.svg";
import { Link } from "react-router-dom";

function AccessLandingPage() {
  let platfromLoginUrl = "https://platform.dev.hireplusplus.com/login";
  let platfromSignUpUrl = "https://platform.dev.hireplusplus.com/Register";
  let parserLoginUrl = "https://parser.dev.hireplusplus.com/login";
  let parserSignupUrl = "https://parser.dev.hireplusplus.com/signup";
  if (
    window.location.origin &&
    (window.location.origin.includes("https://hireplusplus") ||
      window.location.origin.includes("https://www.hireplusplus"))
  ) {
    platfromLoginUrl = "https://platform.hireplusplus.com/login";
    platfromSignUpUrl = "https://platform.hireplusplus.com/Register";
    parserLoginUrl = "https://parser.hireplusplus.com/login";
    parserSignupUrl = "https://parser.hireplusplus.com/signup";
  }

  return (
    <div className="access-landing-page">
      <nav className="w-[90%] md:w-[93%] m-auto pt-[1.7rem]">
        <img
          src={Icon}
          alt="logo"
          width={110}
          height={56}
          className="cursor-pointer"
          onClick={() => (window.location.href = "/")}
        />
      </nav>
      <section className="flex flex-col xl:flex-row justify-between gap-[2.2rem] items-center w-[88%] mx-auto my-[3rem] xl:my-[2rem]">
        <div
          className="w-[98%] xl:basis-[42%] flex gap-4 flex-col text-center md:text-left"
          data-aos="fade-right"
          data-aos-duration="650"
          data-aos-delay="50"
          data-aos-once={true}
          data-aos-easing="ease-in-out"
        >
          <p className="title-text text-[#101828] font-bold">
            <span className="text-[#F79009]">AI Hiring Co-Pilot</span> for
            Sector, Skill, Language Agnostic Lateral Hiring.
          </p>
          <p className="text-[#475467] font-bold text-description">
            Talent Pipeline Generator, Optimizer, and Fitment Reporting SaaS.
            For Global Hiring.
          </p>
          <p className="text-[#475467] text-description">
            Using Hire++, Recruiters can self-optimize the talent pipeline,
            eliminate irrelevant candidates, and enrich it with L1-Shortlisted
            Candidates, generating 360º Fitment Reports.
          </p>
          <p className="text-[#475467] text-description">
            Access the Services you subscribe to, here.
          </p>
        </div>
        <div className="basis-[48%] flex flex-col gap-[0.9rem]">
          <div
            className="flex justify-center items-center "
            data-aos="zoom-in"
            data-aos-duration="650"
            data-aos-delay="50"
            data-aos-once={true}
            data-aos-easing="ease-in-out"
          >
            <div className="bg-[#103662] text-[#F2F8FE] px-4 pb-5 w-[280px] rounded-[10px] shadow hover:scale-[1.024] ease-in-out transition-transform duration-[400ms]">
              <div className="first-product-hero">
                <div className="first-product--icon">
                  <img src={product1} alt="product-1" />
                </div>
                <p className="first-product--name font-semibold text-[0.9em] text-[#F2F8FE]">
                  Hire++ Platform
                </p>
              </div>
              <Link to={platfromLoginUrl}>
                <button className="w-full bg-[#206DC5] py-2 px-4 text-[0.85em] font-semibold rounded-md shadow-sm hover:brightness-[1.13] transition-all duration-200 active:brightness-90">
                  Login
                </button>
              </Link>
              <p className="text-[0.8em] text-[#FCFCFD] font-medium mt-4 text-center">
                Don’t have an account?{" "}
                <span className="font-bold hover:text-[#599fef] transition-colors duration-200">
                  <Link to={platfromSignUpUrl}>
                    Register
                  </Link>
                </span>
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center gap-[1.1rem]">
            <div className="hover:scale-[1.024] ease-in-out transition-transform duration-[400ms]">
              <div
                className="second-product bg-[rgb(17,19,34)] text-[#F2F8FE] px-4 pb-5 w-[280px] rounded-[10px] shadow flex flex-col items-center justify-between"
                data-aos="zoom-in"
                data-aos-duration="650"
                data-aos-delay="50"
                data-aos-once={true}
                data-aos-easing="ease-in-out"
              >
                <div className="h-[175px] flex flex-col items-center justify-center gap-[0.5rem]">
                  <div className="mt-[1.6rem]">
                    <img src={product2} alt="product-2" />
                  </div>
                  <p className="font-semibold text-[0.9em] text-[#F2F8FE]">
                    Proctor++ Video Interviews
                  </p>
                </div>
                <div className="w-full">
                  <Link to={"/contact-us"}>
                    <button className="w-full bg-[#206DC5] py-2 px-4 text-[0.85em] font-semibold rounded-md shadow-sm hover:brightness-[1.13] transition-all duration-200 active:brightness-90">
                      Request Access
                    </button>
                  </Link>
                  <p className="text-[0.8em] text-[#FCFCFD] font-medium mt-4 text-center">
                    Don’t have an account?{" "}
                    <span className="font-bold hover:text-[#599fef] transition-colors duration-200">
                      <Link to={"/contact-us"}>Register</Link>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="hover:scale-[1.024] ease-in-out transition-transform duration-[400ms]">
              <div
                className="third-product bg-[#164C63] text-[#F2F8FE] px-4 pb-5 w-[280px] rounded-[10px] shadow"
                data-aos="zoom-in"
                data-aos-duration="650"
                data-aos-delay="50"
                data-aos-once={true}
                data-aos-easing="ease-in-out"
              >
                <div className="h-[175px] flex flex-col items-center justify-center gap-[0.5rem]">
                  <div className="mt-[1.6rem]">
                    <img src={product3} alt="product-3" />
                  </div>
                  <p className="font-semibold text-[0.9em] text-[#F2F8FE]">
                    Parse++ AI Parsing
                  </p>
                </div>
                <Link to={parserLoginUrl}>
                  <button className="w-full bg-[#206DC5] py-2 px-4 text-[0.85em] font-semibold rounded-md shadow-sm hover:brightness-[1.13] transition-all duration-200 active:brightness-90">
                    Login
                  </button>
                </Link>
                <p className="text-[0.8em] text-[#FCFCFD] font-medium mt-4 text-center">
                  Don’t have an account?{" "}
                  <span className="font-bold hover:text-[#599fef] transition-colors duration-200">
                    <Link to={parserSignupUrl} target="_blank">
                      Register
                    </Link>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AccessLandingPage;
