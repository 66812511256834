let baseUrl =
  window.location.hostname == "localhost"
    ? "https://platform.dev.hireplusplus.com"
    : window.location.origin;

export const contactApi = `${baseUrl}/communication/api/v1/contact/sendContactUsEmail`;
export const recaptchaApi = `${baseUrl}/accountmanager/api/v1/login/verifyCaptcha?response=`;
export const getJobsApi = `${baseUrl}/aimatcher/api/v1/openpool/getOpenJobs`;
export const getJobsCountApi = `${baseUrl}/aimatcher/api/v1/openpool/getOpenJobsCount`;
export const resumeUploadApi = `${baseUrl}/aimatcher/api/v1/openpool/addCandidate`;
export const resumeUploadJdApi = `${baseUrl}/aimatcher/api/v1/openpool/applyForJob`;
export const parserJdUploadApi = `${baseUrl}/aimatcher/api/v1/parser/uploadJd`;
export const parserResumeUploadApi = `${baseUrl}/aimatcher/api/v1/parser/uploadResume`;
export const subscribeApi = `${baseUrl}/communication/api/v1/contact/mailingList/subscribe`;
export const getBroadcastJdData = `${baseUrl}/aimatcher/api/v1/openpool/getBroadcastJdData`;

export const userIdentifier = "NU_6LP1724335741"
export const aiParserResumeFilePostApi = `${baseUrl}/parser/api/v1/resume/parse/try`;
export const aiParserJdFilePostApi = `${baseUrl}/parser/api/v1/jd/parse/try`;

export const aiParserResumeLandingPostApi = `${baseUrl}/parser/api/v1/resume/parse/landingPage`
export const aiParserJdLandingPostApi = `${baseUrl}/parser/api/v1/jd/parse/landingPage`
// export const aiParserResumeFilePostApi = `https://parser.dev.hireplusplus.com/parser/api/v1/resume/parse/try`;
