import React from "react";

function FirstSection({ currLangJSON }) {
    const heroData = currLangJSON.aiParser.heroSection;
  return (
    <div className="first-cases-section mt-16">
      <p className="ai-parser-sub-heading mb-2">{heroData.subHeading}</p>
      <div className="font-[600] text-4xl text-center mb-2">
        {heroData.actionHeading}
      </div>
      <div className="font-semibold text-center  text-base text-[#667085]">
        {heroData.actionDescription}
      </div>
    </div>
  );
}

export default FirstSection;
